<template>
  <Editor v-model="value"
          :api-key="TINY_MCE"
          :init="editorOptions"/>

</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import {mapActions} from "vuex";
export default {
  name: "wsEditorRegular",
  props : ['value'],
  components : {
    Editor
  },
  data() {
    return {
      text : '',
    }
  },
  watch : {
    value(val) {
      this.$emit('input',val)
    }
  },
  computed : {
    editorOptions() {
      return {
        height: 700,
        plugins: 'image powerpaste charmap table link autolink lists advlist fullscreen searchreplace  wordcount preview media hr codesample quickbars imagetools ' +
            '',
        toolbar: 'undo redo | styleselect | fontformats | bold italic underline | alignleft aligncenter alignright alignjustify | removeformat |' +
            'bullist numlist outdent indent | table charmap codesample | link image media | preview fullscreen ',
        menubar: 'custom edit view insert format tools table',
        // menu: {
        //   custom: { title: this.$t('File'),  items: 'save ', }
        // },
        setup: this.customMenyFunction,
        mobile: {
          theme: 'mobile',
        },

        images_upload_handler: this.handleImageAdded,
        quickbars_insert_toolbar: false,
        quickbars_selection_toolbar: 'bold italic underline styleselect  copy',
        contextmenu: 'undo redo | link image  | copy paste | inserttable | cell row column deletetable | help',
        language: this.TINY_MCE_LANG,
        skin: 'outside',
        // content_css : 'naked',
        powerpaste_word_import: 'clean',
        powerpaste_html_import: 'clean',

        browser_spellcheck: true,
        // skins : naked document outside
      }
    },
  },
  methods : {
    ...mapActions('upload', [
      'UPLOAD_CONTENT_IMAGE'
    ]),

    saveAction() {
      this.$emit('save')
    },
    myCustomOnInit() {

    },
    customMenyFunction(editor) {



      editor.on('init', () => {
        this.$emit('loaded',true)
      });

      editor.ui.registry.addMenuItem('save', {
        text: 'Save',
        width: 300,
        icon : 'save',
        shortcut : 'meta+S',
        onAction: () => {
          this.saveAction()
        }
      });
      editor.addShortcut('meta+s', "description of the shortcut", () => { this.saveAction() } );

    },
    async handleImageAdded(fileInput, success) {

      let file = new File([fileInput.blob()],fileInput.filename(), { type:"image/png"} )
      let data = {
        file : file ,
        param : 'lectures_images'
      }

      let result = await this.UPLOAD_CONTENT_IMAGE(data);

      if  (!result) { success('')
        return this.notify( this.$t('NetworkError') )
      }
      success(result.url)
      return this.notify( this.$t('ImageUploaded') )

    },
  }
}
</script>

<style scoped>

</style>